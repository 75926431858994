<template>
  <v-card v-if="id">
    <v-card-title class="primary lighten-1 white--text py-1" style="display: block; box-sizing: content-box">
      <div style="display: flex; justify-content: space-between" class="d-flex chat-title">
        <div style="font-size: 17px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">[{{ chat.vendor }}] {{ chat_name }} ({{ chat.data.user.name }})</div>
        <div>
          <v-btn dark icon @click="$emit('close')" title="Закрыть"><v-icon>fas fa-times</v-icon></v-btn>
        </div>
      </div></v-card-title
    >
    <a-loader v-if="loading" />
    <v-card-text class="whatsapp-chat" ref="chat_block" :style="{ bottom: `${textareaHeight + 5}px` }">
      <div class="whatsapp-chat-row" v-for="(el, i) of data" :key="i">
        <div v-if="i == data.length - 1 || getDateString(el.createdon) !== getDateString(data[i + 1].createdon)" class="row-date">
          <div class="row-date-inner">{{ getDateString(el.createdon) }}</div>
        </div>
        <div v-if="el.id == firstNewMessage" class="row-date" ref="newmessage">
          <div class="row-date-inner">Новое сообщение</div>
        </div>
        <message :item="el" :isNew="testShowNewMess(el, i) || el.id == firstNewMessage" v-intersection="() => onMessageVisible(el)" />
      </div>
    </v-card-text>
    <v-card-actions
      class="whatsapp-input-wrapper pa-1 blue-grey darken-3 white--text"
      ref="chat_block_action"
      style="position: absolute; max-height: 115px; bottom: 0; width: 100%"
    >
      <div class="d-flex" style="width: 100%">
        <div style="width: 100%">
          <v-textarea
            style="max-height: 110px; overflow-y: auto"
            v-if="true"
            v-model="textMessage"
            outlined
            dense
            rows="1"
            auto-grow
            rounded
            filled
            no-resize
            hide-details
            class="whatsapp-input"
            placeholder="Введите текст сообщения"
            @input="adjustTextareaHeight"
          ></v-textarea>
          <input v-else class="whatsapp-input" v-model="textMessage" placeholder="Введите сообщение" />
        </div>
        <div class="pa-1 ml-1">
          <v-btn dark icon @click="sendMessage(textMessage)" title="Послать сообщение"><v-icon>fas fa-paper-plane </v-icon></v-btn>
        </div>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {
    message: () => import("./message.vue"),
    templateDialog: () => import("./templateDialog.vue"),
  },
  props: {
    id: Number,
    isAll: {
      type: Boolean,
      default: false,
    },
    chat: { type: Object, default: () => {} },
  },
  data() {
    return {
      textareaHeight: 48,
      loading: false,
      data: [],
      textMessage: "",
      n: 0,
      firstNewMessage: 0,
      pager: null,
      isTheEnd: null,
      showNewMess: false,
      hasNewMessages: 0,
    };
  },
  created() {
    this.$root.$on("websocket", this.registerWS);
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, true);
  },
  destoyed() {
    document.removeEventListener("scroll", this.onScroll, true);
    this.$root.$off("websocket", this.registerWS);
  },

  computed: {
    chat_name() {
      return this.chat.data.offer.title;
    },
  },
  watch: {
    hasNewMessages(v) {
      if (v == 0) this.scrollToNewMessage();
    },
    id: {
      handler() {
        const page = 50;
        this.textMessage = "";
        this.data = [];
        this.showNewMess = false;
        this.firstNewMessage = 0;
        this.hasNewMessages = -1;
        this.n = 0;
        this.pager = {
          page: 0,
          limit: this.chat?.count_messages_unviewed > page ? this.chat.count_messages_unviewed : page,
        };
        this.isTheEnd = false;
        this.fetchData();
      },
      immediate: true,
    },
  },
  methods: {
    onMessageVisible(el) {
      // Помечаем сообщение как прочитанное, если оно отображено на экране
      if (el.viewed !== 1) {
        this.markAsRead(el);
      }
    },
    markAsRead(message) {
      // Логика отметки сообщения как прочитанного
      // Здесь можно выполнить запрос к серверу для отметки прочитанного сообщения
      console.log("read", message, message.viewed);
      //message.viewed = 1;
      this.setViewed(message.id);
    },
    adjustTextareaHeight() {
      // Динамически вычисляем высоту textarea и обновляем `textareaHeight`
      this.$nextTick(() => {
        const textarea = this.$refs.chat_block_action;
        if (textarea) {
          this.textareaHeight = textarea.offsetHeight - 4;
        }
      });
    },
    testShowNewMess(mess) {
      if (mess && mess.viewed == 0) {
        this.n = mess.id;
      } else if ((mess?.viewed == 1 && !this.showNewMess) || (!mess && this.n)) {
        this.firstNewMessage = this.n;
        if (this.hasNewMessages < 0) this.hasNewMessages = 0;
        this.showNewMess = true;
        return true;
      }
      return false;
    },
    onScroll() {
      if (this.$refs.chat_block) {
        let el = this.$refs.chat_block;
        let offset = 200;
        if (el.scrollHeight - el.offsetHeight <= -el.scrollTop + offset) {
          this.onScrollEnd();
        }
      }
    },
    onScrollEnd() {
      if (this.isTheEnd) {
        this.testShowNewMess(null);
      }
      if (!this.isTheEnd && !this.loading) {
        this.pager.page++;
        this.fetchData();
      }
    },
    scrollToNewMessage() {
      if (this.hasNewMessages !== 0) return;
      // Дожидаемся рендера DOM, чтобы корректно измерить размеры элементов
      this.$nextTick(() => {
        if (!this.$refs.newmessage) return;
        const chatBlock = this.$refs.chat_block;
        const newMessageElement = this.$refs.newmessage[0];
        if (chatBlock && newMessageElement) {
          const chatBlockHeight = chatBlock.clientHeight;
          const newMessagePosition = newMessageElement.offsetTop;
          // Скроллим на позицию нового сообщения с отступом в 20% высоты блока
          const offset = chatBlockHeight * 0.2;
          chatBlock.scrollTop = newMessagePosition - offset;
        }
      });
      this.hasNewMessages++;
    },
    registerWS(data) {
      let regEvent = "chat-message";
      // if (this.isAll) regEvent = "whatsapp_all"
      if (data && data.event == regEvent) {
        const r = data.data;
        if (r.chat_id == this.id) {
          this.data.unshift(r);
        }
      }
      //this.setViewed();
    },
    async sendMessage(message) {
      if (message) {
        try {
          await this.$axios.post("/mechti/chats_message", {
            type: "text",
            message: message,
            chat_id: this.id,
          });
          this.textMessage = "";
        } catch (error) {
          console.error(error);
        }
      }
    },

    async setViewed(id = 0) {
      // if (this.isAll) return;
      try {
        let count = 0;
        for (const el of this.data.filter((d) => d.id == id || !id)) {
          if (el.viewed != 1) {
            await this.$axios.post("/mechti/chats_message", {
              id: el.id,
              viewed: 1,
            });
            count++;
          }
        }
        //this.$root.$emit("set_viewed_wa", { sender_number: this.number });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchData() {
      console.log("fitch", this.id, this.pager.page);
      if (!this.id) return;
      try {
        this.loading = true;
        const params = {
          sort: { key: "createdon", order: "desc" },
          sort1: { key: "id", order: "desc" },
          filters: { chat_id: this.id, status: { condition: "<", value: 110 } },
          pager: JSON.stringify(this.pager),
        };
        const response = await this.$axios.get("/mechti/chats_message/", { params });
        console.log("page", this.pager.page);
        if (this.pager.page > 0) {
          for (const el of response.data.data) {
            this.data.push(el);
          }
        } else {
          this.data = response.data.data;
        }
        //this.setViewed();

        if (response.data.data.length < this.pager.limit) {
          this.isTheEnd = true;
        }
        this.loading = false;

        this.$nextTick(() => {
          this.onScroll();
        });
      } catch (error) {
        console.error(error);
      }
    },
    getDateString(d) {
      let a = new Date(d);
      return a.toLocaleDateString("ru");
    },
    getTimeString(d) {
      let a = new Date(d);
      return a.toLocaleTimeString("ru", { hour: "2-digit", minute: "2-digit" });
    },
  },
  directives: {
    intersection: {
      inserted(el, binding) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              binding.value(); // Вызываем переданную функцию
            }
          },
          {
            threshold: 0.1, // Процент видимости элемента (0.1 = 10%)
          }
        );
        observer.observe(el);
      },
      unbind(el) {
        if (el.__vueIntersectionObserver__) {
          el.__vueIntersectionObserver__.disconnect();
        }
      },
    },
  },
};
</script>

<style lang="scss">
.wa-upload {
  label {
    cursor: pointer;
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}

.whatsapp-input-wrapper {
  // background: #aaa;
}

.whatsapp-input {
  background: #fff;
  border-radius: 6px;
  margin: 5px;
  padding: 8px 10px;
  width: 100%;
  outline: none;
  caret-color: auto;

  scrollbar-width: thin; /* Толщина скроллбара */
  scrollbar-color: #888 #f1f1f1; /* Цвет ползунка и трека */
}

.whatsapp-chat {
  position: absolute;
  overflow: auto;
  top: 44px;
  //bottom: 58px;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  background: url(/img/bg-chat-tile-dark_a4be512e7195b6b733d9110b408f075d.png) repeat #eee;
}

.whatsapp-chat-row {
  .row-date {
    display: flex;
    justify-content: center;

    .row-date-inner {
      margin: 10px;
      padding: 5px 10px;
      background-color: #fff;
      box-shadow: 0px 1px 0 #e2e2e2;
      border-radius: 7.5px;
    }
  }
  .chat-title {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    white-space: nowrap;
  }
  .row-message {
    padding: 0;
    margin: 2px 10px 2px 10px;
    display: flex;

    &.left {
      justify-content: left;

      .row-message-inner {
        background-color: #fff;
      }
    }

    &.right {
      justify-content: right;

      .row-message-inner {
        background-color: #d9fdd3;
      }
    }

    .row-message-inner {
      box-shadow: 0px 1px 0 #e2e2e2;
      white-space: pre-line;
      max-width: 400px;
      border: 1px solid transparent;
      border-radius: 7.5px;
      padding: 8px 10px 2px 10px;
    }

    .row-message-inner-date {
      font-size: 12px;
      color: #aaa;
      margin-left: 30px;
      text-align: right;
    }
  }
}
</style>
